/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: process.env.GATSBY_AWS_REGION,
  aws_cognito_region: process.env.GATSBY_AWS_REGION,
  aws_user_pools_id: process.env.GATSBY_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.GATSBY_AWS_USER_POOLS_WEB_CLIENT_ID,
  oauth: {},
  API: {
    endpoints: [
      {
        name: "event-replay",
        endpoint: process.env.GATSBY_API_ENDPOINT_URL,
      },
    ],
  },
}

export default awsmobile
